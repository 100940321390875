import { withTranslation } from "react-i18next";
import dietro from "./css/style.module.css";
import logo from "./css/img/App_Icon.png";
import Indietro from "./css/img/freccia.png";
import { Link } from "react-router-dom";

function Privacy({t}){

return (
<div className={dietro.Sfondopacity}>
        <Link
        class="transition duration-500 ease-in-out transform hover:opacity-40 h-4 pt-5 pl-2"
        to="/"
      >
        <img class="w-12 " src={Indietro}></img>
      </Link>
      <div class="flex justify-center box-content pb-5 pr-9 "><img src={logo} /></div>
      <div  className={dietro.titolo} >

        <p className={dietro.fontone}>CALMA M</p>
        <p className={dietro.fontwo}>ANAGEMENT</p>
        
      </div>
      <div className="flex flex-col ">
    <p className="flex h-44 pb-80 whitespace-pre-line text-center md:mx-44 mx-8 Monteserrat font-extrabold ">{t("Privacy")}</p></div>

</div>);


}
export default withTranslation ()(Privacy);