import dietro from "./compont/css/style.module.css";
import logo from "./compont/css/img/App_Icon.png";
import langIt from "./compont/css/img/iticon.png";
import Bottone from "./compont/bottonedrop.js";
import langEn from "./compont/css/img/enicon.png";
import React ,{useEffect , useState } from 'react';
import { useTranslation } from 'react-i18next';
import Selectlang from "./compont/lang.js";
import i18n from 'i18next';
import { withTranslation } from 'react-i18next';
import { useCookies } from 'react-cookie';
import { Link } from "react-router-dom";
import ReactGA from 'react-ga';


function Home({t,i18n}) {
  const [cookies, setCookie] = useCookies(["Cookie_consent"]);
  function handleCookie() {

    document.getElementById("popup_cookie").style.display="none";
    setCookie("Cookie_consent", true , {path: "/"   });
    ReactGA.initialize('UA-159062681-1');
    ReactGA.pageview(window.location.pathname + window.location.search);
  }
  
  
  return (
    <div className={dietro.Sfondoc}>
      <Selectlang/>
      <div class="flex justify-center box-content pb-5 pr-9 "><img src={logo} /></div>
      <div  className={dietro.titolo} >

        <p className={dietro.fontone}>CALMA M</p>
        <p className={dietro.fontwo}>ANAGEMENT</p>
        
      </div>

      
      <div className={dietro.abotthome}>
        
        <Bottone titolo={t("Home.Chi_siamo")} percorso="/Chi_siamo"/>
        <Bottone titolo={t("Home.Artisti")}  percorso="/artisti"/>
        <Bottone titolo={t("Home.Progetti")}  percorso="/progetti"/>
        <Bottone titolo={t("Home.contatti")}  percorso="/Contatti"/>
        

      </div>
      
     {!cookies.Cookie_consent && <div id="popup_cookie" className=" bg-black bottom-0 items-center flex flex-col w-full fixed"><p  className="text-white place-items-end m-4 mx-10 " >{t("Home.Cookie")} <Link to="/privacy" className="hover:underline font-bold" >{t("Home.privacy")}</Link></p>
      <button onClick={handleCookie} className="h-8 my-2 center  bg-yellow-400 rounded-md   w-20 font-semibold  text-black">{t("Home.button_cookie")}</button>
     </div> }
    </div>
  );
}
export default withTranslation()(Home);