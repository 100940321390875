import React from 'react';
import dietro from "./css/style.module.css";
import {Link} from "react-router-dom";

export default function Bottone(diretive){
    return(

    <div class="flex flex-col  pb-4 Monteserrat-ExtraLight">
        <div class="flex justify-center Monteserrat-ExtraLight">
          <Link to={diretive.percorso} className={dietro.bottone}id={diretive.id}>
          {diretive.titolo}
        </Link>
        </div>
        
        <Link to={diretive.percorso} class="text-center  Monteserrat-ExtraLight"><p> {diretive.descrizione}</p></Link>
        <Link to={diretive.percorso} class="text-center Monteserrat-ExtraLight  " ><p>{diretive.descrizione2}</p></Link>
    </div>
    

    );

}
