import dietro from "./css/style.module.css";
import logo from "./css/img/logo_icon.png";
import Indietro from "./css/img/freccia.png";
import { Link } from "react-router-dom";


export default function Navbar(diretive) {


    return (
        <div className={dietro.Coloremobile}>
            <Link class=" h-4 pt-1 static" to={diretive.percorso} id={diretive.id}>
                <img class=" transition duration-500 ease-in-out transform md:hover:opacity-40 w-14 pt-4 pl-2 " src={Indietro}></img>
                 </Link>
            <div class="flex w-full justify-center  box-content pt-2 h-full pb-10 md:pt-10  "><img class="flex w-96 transform md:scale-125" src={logo} />

</div>

        </div>





    );




}