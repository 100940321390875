import Indietro from "./css/img/freccia.png";
import { Link } from "react-router-dom";
import { useQuery, gql } from "@apollo/client";
import { useParams } from "react-router";
import dietro from "./css/style.module.css";
import Linksocial from "./linksocial.js";

import { useState, useEffect } from "react";
import load from "./css/img/load.gif";
import axios from "axios";
import i18n from "i18next";

import ImageGallery from "react-image-gallery";
import "react-image-gallery/styles/css/image-gallery.css";

export default function Progetto() {
  const [slider1, Setslider1] = useState([]);
  const [slider2, Setslider2] = useState([]);
  const [slider3, Setslider3] = useState([]);
  const [slider4, Setslider4] = useState([]);
  let { id } = useParams();
  const Progg = gql`
    query get_InfoProjects($id: ID!) {
      post(id: $id) {
        projects {
          amazonmusicProjects
          applemusicProjects
          categoria
          categoriaEn
          deezerProjects
          description
          descriptionEn
          facebookProjects
          fieldGroupName
          gallery
          instagramProjects
          linkedinProjects
          name
          player1
          player1Check
          player2
          player2Check
          player3
          player3Check
          player4
          player4Check
          produzioni
          produzioniEn
          soudcloudProjects
          spotifyProjects
          twitterProjects
          vimeoProjects
          website
          youtubeProjects
        }
      }
    }
  `;

  const { loading, error, data } = useQuery(Progg, { variables: { id } });
  useEffect(() => {
    if (data) imagesimporter(data.post.projects.gallery);
  }, data);

  const imagesimporter = (folder) => {
    console.log(folder);
    axios
      .get("https://www.calma.management/getimage.php?name=" + folder)
      .then((res) => {
        res.data.slider1.map((x) => {
          if (x[0] != ".")
            Setslider1((slider1) => [
              ...slider1,
              {
                original:
                  "https://www.calma.management/foto/" +
                  folder +
                  "/slider1/" +
                  x,
              },
            ]);
        });
        res.data.slider2.map((x) => {
          if (x[0] != ".")
            Setslider2((slider2) => [
              ...slider2,
              {
                original:
                  "https://www.calma.management/foto/" +
                  folder +
                  "/slider2/" +
                  x,
              },
            ]);
        });
        res.data.slider3.map((x) => {
          if (x[0] != ".")
            Setslider3((slider3) => [
              ...slider3,
              {
                original:
                  "https://www.calma.management/foto/" +
                  folder +
                  "/slider3/" +
                  x,
              },
            ]);
        });
        res.data.slider4.map((x) => {
          if (x[0] != ".")
            Setslider4((slider4) => [
              ...slider4,
              {
                original:
                  "https://www.calma.management/foto/" +
                  folder +
                  "/slider4/" +
                  x,
              },
            ]);
        });
        console.log(res);
      });
  };

  const slideNumberStyle = {
    fontSize: "20px",
    fontWeight: "bold",
  };

  const carouselData = (x) => {
    x.map((row) => {
      return {
        key: String(row.id),
        children: (
          <div
            style={{
              backgroundImage: `url(${row.image})`,
            }}
          />
        ),
      };
    });
  };
  if (loading)
    return (
      <div className={dietro.Coloreload}>
        {" "}
        <p className="text-center pt-60 fixed text-3xl w-full h-full ">
          {" "}
          Loading
        </p>
        <div className="flex w-full pt-10 justify-center items-center">
          <img
            className="flex center pt-60 justify-center w-11 items-center"
            src={load}
          ></img>
        </div>
      </div>
    );
  if (error) return <p> error </p>;
  return (
    <div className={dietro.Colore}>
      <Link class="   h-4 pt-1" to="/progetti">
        <img
          class="transition duration-500 ease-in-out transform hover:opacity-40 w-14 fixed pt-4 pl-2 "
          src={Indietro}
        ></img>
      </Link>
      <div class="flex md:flex-row flex-col  gap-x-3  w-full justify-between">
        <div class="flex flex-col md:pl-24 pt-24 h-full text-center md:text-left  md:w-1/3 ">
          <p class="Monteserrat-Light font-semibold text-2xl text-black opacity-80">
            {" "}
            <div
              dangerouslySetInnerHTML={{ __html: data.post.projects.name }}
            />
          </p>
          <p class="Monteserrat font-light pl-1.5 pt-1  ">
            {i18n.resolvedLanguage == "it"
              ? data.post.projects.categoria
              : data.post.projects.categoriaEn}
          </p>
        </div>
        <div class="md:w-1/3"></div>
        <div class="flex  md:w-1/3 w-full md:h-32 py-5 px-4 md:m-4">
          <Linksocial
            facebook={data.post.projects.facebookProjects}
            instagram={data.post.projects.instagramProjects}
            youtube={data.post.projects.youtubeProjects}
            apple={data.post.projects.applemusicProjects}
            amazon={data.post.projects.amazonmusicProjects}
            spotify={data.post.projects.spotifyProjects}
            vimeo={data.post.projects.vimeoProjects}
            linkdin={data.post.projects.linkedinProjects}
            soundcloud={data.post.projects.soundcloudProjects}
            deezer={data.post.projects.deezerProjects}
          />
        </div>
      </div>
      <div class="flex md:flex-row flex-col w-full h-full min-h-screen  gap-x-3 md:pt-14 justify-between">
        <div class="md:w-1/3 m-4">
          {slider1.length != 0 && (
            <div className="m-4">
              <ImageGallery
                className="p-2"
                items={slider1}
                showBullets={true}
                showPlayButton={false}
              />
            </div>
          )}
          {slider2.length != 0 && (
            <div className="m-4">
              <ImageGallery
                className="p-2"
                items={slider2}
                showBullets={true}
                showPlayButton={false}
              />
            </div>
          )}
          {slider3.length != 0 && (
            <div className="m-4">
              <ImageGallery
                className="p-2"
                items={slider3}
                showBullets={true}
                showPlayButton={false}
              />
            </div>
          )}
          {slider4.length != 0 && (
            <div className="m-4">
              <ImageGallery
                className="p-2"
                items={slider4}
                showBullets={true}
                showPlayButton={false}
              />
            </div>
          )}
        </div>
        <div class="md:w-1/3 h-full m-4">
          <p className={dietro.testoArtista}>
            <div
              dangerouslySetInnerHTML={
                i18n.resolvedLanguage == "it" ||
                data.post.projects.descriptionEn == null
                  ? { __html: data.post.projects.description }
                  : { __html: data.post.projects.descriptionEn }
              }
            />
            <br />
          </p>
          {data.post.projects.website != null && (
            <div class="flex w-full  justify-center items-center">
              <div className={dietro.single_button}>
                <a href={data.post.projects.website}>website</a>
              </div>
            </div>
          )}
        </div>

        <div className="flex flex-col md:w-1/3 w-full h-full items-center justify-center gap-y-1.5 md:m-4">
          {data.post.projects.player1Check == "youtube" && (
            <iframe
              class=" flex md:w-full w-11/12  "
              height="265"
              allowfullscreen
              src={
                "https://www.youtube.com/embed/" + data.post.projects.player1
              }
              frameborder="0"
            ></iframe>
          )}
          {data.post.projects.player1Check == "spotify" && (
            <iframe
              src={
                "https://open.spotify.com/embed/artist/" +
                data.post.projects.player1
              }
              width="100%"
              height="380"
              frameborder="0"
              allowtransparency="true"
              allow="encrypted-media"
            ></iframe>
          )}
          {data.post.projects.player1Check == "spotify_user" && (
            <iframe
              src={
                "https://open.spotify.com/embed/user/" +
                data.post.projects.player1
              }
              width="100%"
              height="380"
              frameborder="0"
              allowtransparency="true"
              allow="encrypted-media"
            ></iframe>
          )}
          {data.post.projects.player2Check == "youtube" && (
            <iframe
              class=" flex md:w-full w-11/12 "
              height="265"
              src={
                "https://www.youtube.com/embed/" + data.post.projects.player2
              }
            ></iframe>
          )}

          {data.post.projects.play2Check == "spotify" && (
            <iframe
              src={
                "https://open.spotify.com/embed/artist/" +
                data.post.projects.player2
              }
              width="100%"
              height="380"
              frameborder="0"
              allowtransparency="true"
              allow="encrypted-media"
            ></iframe>
          )}
          {data.post.projects.player2Check == "spotify_user" && (
            <iframe
              src={
                "https://open.spotify.com/embed/user/" +
                data.post.projects.player2
              }
              width="100%"
              height="380"
              frameborder="0"
              allowtransparency="true"
              allow="encrypted-media"
            ></iframe>
          )}

          {data.post.projects.player3Check == "youtube" && (
            <iframe
              class=" flex md:w-full w-11/12  "
              height="265"
              src={
                "https://www.youtube.com/embed/" + data.post.projects.player3
              }
            ></iframe>
          )}

          {data.post.projects.player3Check == "spotify" && (
            <iframe
              src={
                "https://open.spotify.com/embed/artist/" +
                data.post.projects.player3
              }
              width="100%"
              height="380"
              frameborder="0"
              allowtransparency="true"
              allow="encrypted-media"
            ></iframe>
          )}
          {data.post.projects.player3Check == "spotify_user" && (
            <iframe
              src={
                "https://open.spotify.com/embed/user/" +
                data.post.projects.player3
              }
              width="100%"
              height="380"
              frameborder="0"
              allowtransparency="true"
              allow="encrypted-media"
            ></iframe>
          )}

          {data.post.projects.player4Check == "youtube" && (
            <iframe
              class=" flex md:w-full w-11/12   "
              height="265"
              src={
                "https://www.youtube.com/embed/" + data.post.projects.player4
              }
            ></iframe>
          )}
          {data.post.projects.player4Check == "spotify" && (
            <iframe
              src={
                "https://open.spotify.com/embed/artist/" +
                data.post.projects.player4
              }
              width="100%"
              height="380"
              frameborder="0"
              allowtransparency="true"
              allow="encrypted-media"
            ></iframe>
          )}
          {data.post.projects.player4Check == "spotify_user" && (
            <iframe
              src={
                "https://open.spotify.com/embed/user/" +
                data.post.projects.player4
              }
              width="100%"
              height="380"
              frameborder="0"
              allowtransparency="true"
              allow="encrypted-media"
            ></iframe>
          )}
        </div>
      </div>
    </div>
  );
}
