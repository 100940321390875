import dietro from "./compont/css/style.module.css";
import Bottone from "./compont/buttonsmall.js";
import Navbar from "./compont/navproj.js";
import load from "./compont/css/img/load.gif"
import { useQuery, gql } from "@apollo/client";
import i18n from "i18next";

function ListaProgetti() {
  const Proj = gql`
    query get_ListaProgetti {
      posts(first: 200) {
        edges {
          node {
            projects {
              categoria
              categoriaEn
              name
              fieldGroupName
            }
            id
          }
        }
      }
    }
  `;

const { loading, error, data } = useQuery(Proj);
  if (loading)
    return (
      <div className={dietro.Coloreload}> <p className="text-center fixed text-3xl w-full h-full "> Loading</p><div className="flex w-full justify-center items-center"><img className="flex center pt-10 justify-center w-11 items-center" src={load} ></img></div>
      </div> 
    );

  if (error)
    return (
      <div className={dietro.Coloremobile}>
        <p className="text-center text-3xl w-full"> Error </p>{" "}
      </div>
    );
  const flushed = data.posts.edges.map((i) => {
    if (i.node.projects.name != null) return i.node;
    else return null;
  });
  const clean = flushed.filter((x) => x);
  console.log(clean);
  console.log(clean.map((x) => x.projects.name));
  return clean.map((node) => (
    <Bottone
      titolo={i18n.resolvedLanguage=="it" ? node.projects.categoria: node.projects.categoriaEn}
      percorso={"/progetto/" + node.id}
      descrizione={node.projects.name}
      
    />
  ));
}

export default function navproj(diretive) {
  return (
    <div className="md:fixed md:h-full w-full">
      <Navbar percorso="/" />
      <div className={dietro.abottproj}>
      <ListaProgetti />
      
      </div>
    </div>
  );
}
