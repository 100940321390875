import dietro from "./compont/css/style.module.css";
import logo from "./compont/css/img/App_Icon.png";
import Bottone from "./compont/buttonsmall.js";
import Navbar from "./compont/nav.js";
import { useQuery, gql } from "@apollo/client";
import React, { useState, useEffect, useRef, useContext } from "react";
import { Context } from "./Context.js";
import load from "./compont/css/img/load.gif"
import i18next from "i18next";
import { withTranslation } from 'react-i18next';

function ListaArtisti({ Lista }) {
  const [filter, setFilter] = useContext(Context);

  const Art = gql`
    query Lista_artisti {
      posts(first: 200) {
        edges {
          node {
            id
            artista {
              instruments
              nome
              cognome
              genre
              instrumentsEn
              
            }
          }
        }
      }
    }
  `;
  const { loading, error, data } = useQuery(Art);
  if (loading)
    return (
      <div className={dietro.Coloreload}> <p className="text-center fixed text-3xl w-full h-full "> Loading</p><div className="flex w-full justify-center items-center"><img className="flex center pt-10 justify-center w-11 items-center" src={load} ></img></div>
      </div> 
    );

  if (error)
    return (
      <div className={dietro.colore}>
        <p className="text-center text-3xl w-full"> Error </p>{" "}
      </div>
    );
  if (data) Lista(data);
  const flushed = data.posts.edges.map((i) => {
    if (i.node.artista.nome != null && (i.node.artista.genre== filter.genere || filter.genere == "all") && ((i18next.resolvedLanguage=="it"? i.node.artista.instruments: i.node.artista.instrumentsEn) == filter.strumento || filter.strumento == "all")) return i.node;
    else return null;
  });
  const clean = flushed.filter((x) => x);
  return clean.map((node) => (
    <Bottone
      genere={node.artista.genre}
      titolo={i18next.resolvedLanguage=="it" ? node.artista.instruments: node.artista.instrumentsEn}
      percorso={"/artista/" + node.id}
      descrizione={node.artista.nome}
      descrizione2={node.artista.cognome}
    />
  ));
}

 function Artisti({t}) {
  const [lista, setLista] = useState([]);
  const [filter, setFilter] = useState({ strumento: "all", genere: "all" });
  return (
    <Context.Provider value={[filter, setFilter]}>
      <div className=" md:h-full w-full">
        <Navbar percorso="/" option={lista} />
        <div className={dietro.abottartisti}>
          <ListaArtisti Lista={(x) => setLista(x)} />
        </div>
      </div>
    </Context.Provider>
  );
}
export default withTranslation()(Artisti);
