import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import XHR from 'i18next-xhr-backend';
import translationEn from './loacal/en/en.json';
import translationIt from './loacal/it/it.json';

const resources ={
en:{
  translation: translationEn
},
it:{

  translation: translationIt


}
};



i18n
.use(XHR)
.use(initReactI18next) 
.init({
    resources,
    lng: "it",

    keySeparator: false,

    interpolation: {
      escapeValue: false
    }
});

export default i18n;