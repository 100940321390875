import dietro from "./css/style.module.css";
import logo from "./css/img/logo_icon.png";
import Indietro from "./css/img/freccia.png";
import { Link } from "react-router-dom";
import FB from "./css/img/facebook_Icone.png";
import Insta from "./css/img/instagram_Icone.png";
import Youtube from "./css/img/youtube_Icone.png";
import linkedin from "./css/img/linkedin_Icone.png"




export default function Downbar() {


    return (
        <div class="flex items-center center justify-center flex-row   w-full md:gap-x-60 gap-x-14 ">

            <a  href="https://www.facebook.com/pg/CALMA-Management-101891548042655/posts/">
            <img class="w-8 center " src={FB}></img>
            </a>
            <a href="https://www.instagram.com/calma_management/">
            <img class="w-8" src={Insta}></img>
            </a>
            <a href="https://www.youtube.com/channel/UCJMbeWV6azvgutvLUOHHuRw" >
            <img class="w-8" src={Youtube}></img>
            </a>
            <a href="https://www.linkedin.com/company/37214159/admin/">
            <img class="w-8" src={linkedin}></img>
            </a>
            





        </div>
    




    );


}