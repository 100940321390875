import "./App.css";
import "tailwindcss/tailwind.css";
import Home from "./pages/home.js";
import { Router, Route, Switch, BrowserRouter } from "react-router-dom";
import Artisti from "./pages/artisti.js";
import Chi from "./pages/Chisiamo.js";
import Progetti from "./pages/progetti.js";
import Contatti from "./pages/Contatti.js";
import Artista from "./pages/compont/artista.js";
import Progetto from "./pages/compont/progetto.js";
import { useQuery, gql } from "@apollo/client";
import { CookiesProvider } from 'react-cookie';
import Privacy from "./pages/compont/privacy.js";
import { useTranslation, withTranslation } from 'react-i18next';

function App({t, i18n}) {
  return (
    <CookiesProvider>
    <BrowserRouter>
      <Switch>
        <Route path="/" exact>
          <Home />
        </Route>
        <Route path="/privacy">
          <Privacy />
        </Route>
        <Route path="/chi_siamo">
          <Chi />
        </Route>
        <Route path="/artisti">
          <Artisti />
        </Route>
        <Route path="/contatti">
          <Contatti />
        </Route>
        <Route path="/progetti">
          <Progetti />
        </Route>
        <Route path="/progetto/:id">
          <Progetto />
        </Route>
        <Route path="/artista/:id">
        <Artista />
        </Route>
    </Switch>
    </BrowserRouter>
    </CookiesProvider>
  );
}

export default withTranslation()(App);
