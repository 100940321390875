import React from "react";
import dietro from "./css/style.module.css";
import "./css/dropdown.css";
import { Link } from "react-router-dom";

export default function Bottone(diretive){
  return (
    <div class="flex flex-col  pb-0 Monteserrat-ExtraLight" >
      <div class="flex justify-center Monteserrat-ExtraLight  ">
        <Link
          to={diretive.percorso}
          className={dietro.bottonesmall}
          id={diretive.id}
        >
          {diretive.titolo}
        </Link>
      </div>
      <div class="flex flex-col  hover:underline ">
        <Link
          to={diretive.percorso}
          class="text-center Monteserrat-Light flex justify-center text-black text-base font-bold  "
        >
          <p className="w-56"  dangerouslySetInnerHTML={{ __html: diretive.descrizione}} /> 
        </Link>
        <Link
          to={diretive.percorso}
          class="text-center Monteserrat-Light text-black text-base font-bold "
        >
          <p>{diretive.descrizione2}</p>
        </Link>
      </div>
    </div>
  );
}