import dietro from "./compont/css/style.module.css";
import Indietro from "./compont/css/img/freccia.png";
import logo from "./compont/css/img/App_Icon.png";
import line from "./compont/css/img/line.png";
import { Link } from "react-router-dom";
import Down from "./compont/down_pag.js";

import { withTranslation } from "react-i18next";

 function Contatti({t}) {
  function OpenNewsLetter(){
    window.open("http://newsletter.calma.management:3000/subscription/An9NEWpDK", "_blank", "toolbar=yes,scrollbars=yes,resizable=yes,top=500,left=500,width=900,height=900")
  }
  return (
    <div className={dietro.Colorecontatti}>
      <div class="flex flex-col w-full h-full fixed">
        <Link
          class="transition duration-500 ease-in-out transform md:hover:opacity-40 h-4 pt-1"
          to="/"
        >
          <img class="w-14 pt-4 pl-2" src={Indietro}></img>
        </Link>
        <div class="flex flex-row justify-center gap-x-64 md:pt-0 pt-24  md:h-48 h-0 md:w-full w-0 text-center md:visible invisible">
          <div class="flex flex-col text-center  md:pt-32  text-black  ">
            <p className={dietro.MonteserratSottile}>Stefania Bellodi </p>
            <p className={dietro.MonteserratSottile}>
              stefania(at)calma.management
            </p>
            <div class="flex flex-row text-center justify-center">
              <p className={dietro.MonteserratSottile}>{t("contatti.phone")}</p>
              <p class="select-all Montserrat-Light"> +39 348 64 18 049</p>
            </div>
          </div>
          <div class="flex justify-center box-content md:py-5 pt-5 md:pr-5">
            <img class="w-44 h-44" src={logo} />
          </div>
          <div class="md:pt-32 text-black text-center   font-light ">
            <p className={dietro.MonteserratSottile}>Alessandro Padovan</p>
            <p className={dietro.MonteserratSottile}>
              alessandro(at)calma.management
            </p>
            <div class="flex flex-row text-center justify-center ">
              <p className={dietro.MonteserratSottile}>{t("contatti.phone")}</p>{" "}
              <p class="select-all Montserrat-Light"> +39 340 77 61 429</p>
            </div>
          </div>
        </div>

        <div class="flex flex-col justify-center text-center md:gap-x-52 md:w-0 w-full md:h-0 h-48 md:invisible visible">
          <div class="flex justify-center box-content pt-24 ">
            <img src={logo} />
          </div>
          <div class="flex flex-col  pt-5 text-black   text-center">
            <p className={dietro.MonteserratSottile}>Stefania Bellodi</p>
            <p className={dietro.MonteserratSottile}>
              stefania(at)calma.management
            </p>
            <p className={dietro.MonteserratSottile}>{t("contatti.phone")}<p class="select-all Montserrat-Light"> +39 348 64 18 049</p></p>
          </div>

          <div class="pt-24 text-black">
            <p className={dietro.MonteserratSottile}>Alessandro Padovan</p>
            <p className={dietro.MonteserratSottile}>
              alessandro(at)calma.management
            </p>
            <p className={dietro.MonteserratSottile}>{t("contatti.phone")}<p class="select-all Montserrat-Light ">+39 340 77 61 429</p></p>
          </div>
        </div>
        <div class="flex justify-center md:pb-32 pb-16 pt-10 md:pt-28">
          <button onClick={OpenNewsLetter} class="border  border-black rounded-lg border-b-2 border-r-2 uppercase  bg-transparent text-center font-bold border-opacity- Monteserrat-ExtraLight w-64">
          {t("contatti.newsletter")}
          </button>
        </div>
        <img class="md:pt-78 pt-20" src={line}></img>
        <Down />
        <div className={dietro.indirizzo} >
         {t("contatti.indirizzo")}
        </div>
      </div>
    </div>
  );
}
export default withTranslation()(Contatti);
