import dietro from "./compont/css/style.module.css";
import logo from "./compont/css/img/App_Icon.png";
import Indietro from "./compont/css/img/freccia.png";
import Down from "./compont/down_pag.js";
import { withTranslation } from "react-i18next";
import { useQuery, gql } from "@apollo/client";
import i18n from "i18next";
import load from "./compont/css/img/load.gif"

import { Link } from "react-router-dom";

 function  Chisiamo({t}) {
  const Chi_siamo = gql`query Lista_artisti {
    post(id: "%20%22cG9zdDoyMTQ=") {
      id
      postId
      whoweare {
        description
        descriptionEn
        fieldGroupName
      }
    }
  }`;

  const { loading, error, data } = useQuery(Chi_siamo);
if(loading){
  return(<div className={dietro.Coloreload}> <p className="text-center pt-60 fixed text-3xl w-full h-full "> Loading</p><div className="flex w-full pt-10 justify-center items-center"><img className="flex center pt-60 justify-center w-11 items-center" src={load} ></img></div>
  </div>); 

}
if(error){
  return(<div class="text-center font-semibold ">Errore caricamento <br/>
  ricarica e riprova </div>)
}

  return (
    
    <div className={dietro.Sfondopacity}>
      <Link
        class=" h-4 pt-5 md:pl-2 flex pl-2 w-16 "
        to="/"
      >
        <img class="transition duration-500 ease-in-out transform hover:opacity-40 w-12 fixed" src={Indietro}></img>
      </Link>
      <div class="flex justify-center box-content py-3 pr-10 md:pb-11">
        <img  height="" src={logo} />
      </div>
      <div class="flex  flex-col  h-full  content-center justify-center items-center  md:px-16 antialiased Monteserrat font-light text-black md:pb-24 pb-10">
        <div className={i18n.resolvedLanguage=="it" ?dietro.Chi_siamo_It:dietro.Chi_siamo_En}  dangerouslySetInnerHTML={i18n.resolvedLanguage=="it" ?{ __html:data.post.whoweare.description}:{ __html:data.post.whoweare.descriptionEn}}  ></div>
  
      </div>
      <Down />
      <div class=" pb-12 h-full"></div>
    </div>
  );
}
export default withTranslation()(Chisiamo);
