import { withTranslation } from "react-i18next";

import langEn from "./css/img/enicon.png";
import langIt from "./css/img/iticon.png";
import React, { useEffect, useState } from "react";
import i18next from "i18next";

function Sceglilingua({ t, i18n }) {
  const [foto, setfoto] = useState(i18next.resolvedLanguage=="it" ? langIt: langEn );
  const change = () => {
    if (foto == langIt) {
      setfoto(langEn);
      i18n.changeLanguage("en");
      localStorage.setItem("lang", "en")
    } else {
      setfoto(langIt);
      i18n.changeLanguage("it");
      localStorage.setItem("lang", "it")
    }
  };

  return (
    <a
      class="transition duration-500 ease-in-out transform md:hover:opacity-60"
      onClick={() => change()}
    >
      {" "}
      <img src={foto} class=" w-16" />{" "}
    </a>
  );
}
export default withTranslation()(Sceglilingua);
