import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from "./reportWebVitals";
import './i18n.js';
import i18n from "i18next";

  
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  useQuery,
  gql
} from "@apollo/client";

i18n.changeLanguage(localStorage.lang);

const client = new ApolloClient({
  uri: 'https://www.calma.management/backend/wp/wordpress/graphql',
  cache: new InMemoryCache()
 
 
 })
 


ReactDOM.render(
   <React.StrictMode>
     <ApolloProvider client={client}>
      <App />
     </ApolloProvider>
  </React.StrictMode>,
  document.getElementById('root')

);
 




// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
