import FB from "./css/img/facebook_Icone.png";
import Insta from "./css/img/instagram_Icone.png";
import Youtube from "./css/img/youtube_Icone.png";
import AppleMusic from "./css/img/AppleMusic_Icone.png";
import AmzonMusic from "./css/img/Amazon_Icone.png";
import Spotify from "./css/img/Spotify_Icone.png";
import Vimeo from "./css/img/Vimeo_Icone.png";
import Linkdin from "./css/img/linkedin_Icone.png";
import dietro from "./css/style.module.css";
import deezer from "./css/img/deezer_Icone.png";
import sound_cloud from "./css/img/sound_cloud_Icone.png";


export default function Linksocial(contatti){
    return(

    <div class=" w-full flex justify-around   md:pt-14 md:pl-4   ">
    {contatti.linkdin != null && <a href={contatti.linkdin}>
  <img class="w-8" src={Linkdin}></img></a>}
    {contatti.facebook != null && <a href={contatti.facebook}>
      <img class="w-8" src={FB}></img>
    </a>}
    {contatti.instagram != null &&  <a href={contatti.instagram}>
      <img class="w-8" src={Insta}></img>
    </a>}
    {contatti.youtube != null && <a href={contatti.youtube}>
      <img class="w-8" src={Youtube}></img>
    </a>}
    {contatti.apple != null && <a href={contatti.apple}>
      <img class="w-8" src={AppleMusic}></img>
    </a>}
    {contatti.amazon != null && <a href={contatti.amazon}>
      <img class="w-8" src={AmzonMusic}></img>
    </a>}
    {contatti.spotify != null &&<a href={contatti.spotify}>
      <img class="w-8" src={Spotify}></img>
    </a>}

    {contatti.deezer != null &&<a href={contatti.deezer}>
      <img class="w-8" src={deezer}></img>
    </a>}
    {contatti.sound_cloud != null &&<a href={contatti.sound_cloud}>
      <img class="w-8" src={sound_cloud}></img>
    </a>}
    {contatti.vimeo != null && <a href={contatti.vimeo}>
      <img class="w-8" src={Vimeo}></img></a>}
      
      </div>
      
      );


}