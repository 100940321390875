import dietro from "./css/style.module.css";
import "./css/dropdown.css";
import logo from "./css/img/logo_icon.png";
import Indietro from "./css/img/freccia.png";
import { Link } from "react-router-dom";
import { useRef, useContext, useState } from "react";
import { Context } from "../Context.js";
import { withTranslation } from 'react-i18next';
import i18n from "i18next";

function RemoveDuplicate(data) {
  return [...new Set(data)];
}

function Dropdown(child) {
  const [filter, setFilter] = useContext(Context);

  const dropdown = useRef();
  

  const OnBtnClick = () => {
   
    if (dropdown.current.style.display == "block"){
      
      dropdown.current.style.display = "none";
     }
    else{ dropdown.current.style.display = "block";};
  };
  const OffBtnClick = () => {
    console.log('chiuditi!');

    dropdown.current.style.display = "none";
  };
 

  const setfiltri = (e) => {
    console.log(child.value);
    if (child.value == "genere") {
      setFilter({ strumento: filter.strumento, genere: e.target.name });
    } else {
      setFilter({ strumento: e.target.name, genere: filter.genere });
    }
    dropdown.current.style.display = "none";
  };
  const funtionBlur = (event) => {
    console.log('event.relatedTarget', event.relatedTarget);
    if (!event.currentTarget.contains(event.relatedTarget)) {
      if (dropdown.current.style.display == "block") {
        dropdown.current.style.display = "none";
      }
    }
  }

  const List = () => {
    var option_list = [];
    if (child.option.posts) {
      if (child.value == "genere") {
        option_list = child.option.posts.edges.map((x) => x.node.artista.genre);
      } else {
        option_list = child.option.posts.edges.map(
          (x) => i18n.resolvedLanguage=="it" ? x.node.artista.instruments: x.node.artista.instrumentsEn
        );
      }
      const opzioni = RemoveDuplicate(option_list);
      return (
        <div className=" flex flex-col  transselect" >
          <button
            class="duration-1000 dropdown-item ease-in-out  bg-gradient-to-r  focus:bg-black focus:text-white md:hover:bg-black hover:text-white  text-black capitalize rounded"
            name="all"
            type="button"
            onClick={(e) => setfiltri(e)}
            
            
          >
            all
          </button>
          
          {opzioni.map((x) => (
            <button
              class="duration-1000 dropdown-item ease-in-out  bg-gradient-to-r  focus:bg-black focus:text-white md:hover:bg-black hover:text-white  text-black capitalize rounded"
              name={x}
              type="button"
              onClick={(e) => setfiltri(e)}
              
            >
              {x}
            </button>
          ))}
        </div>
      );
    } else {
      return <div>LOADING</div>;
    }
  };

  return (
    <div class="dropdown "  onBlur={funtionBlur}>
      <button
        class="flex items-center justify-center text-center dropdown-personalized  dropdown-border w-28 dropdownbordertop   dropdown-toggle"
        type="button"
        id="dropdownMenu1"
        data-toggle="dropdown"
        aria-haspopup="true"
        aria-expanded="false"
        onClick={OnBtnClick}
       
        
      ><p>
        {child.title}</p>
      </button>
      <div class="transition duration-700  dropdown-menu" ref={dropdown}  aria-labelledby="dropdownMenu1" >
        <List />
        
      </div>
    </div>
  );
}

 function Navbar({t,percorso,option }) {
  return (
    <div className={dietro.Coloremobile} >
      <Link class="   h-4 pt-1" to={percorso} >
        <img
          class="transition duration-500 ease-in-out transform md:hover:opacity-40 w-14 pt-4 pl-2 "
          src={Indietro}
        ></img>
      </Link>
      <div class="flex w-full flex-row justify-center pr-14 gap-x-32 place-self-start md:place-items-end md:pb-14 ">
        <div className=" md:h-full h-0 md:visible pr-32 md:w-32 w-0 invisible flex  flex-col"><Dropdown
          title={t("artista.Genere")}
          value="genere"
          main="all"
          option={option}
        /></div>
        <div class="flex justify-center w-96 box-content pt-2 md:pr-14 pr-14 h-24 md:pt-10  ">
          <img  class="flex fixed md:w-96 transform scale-125 md:h-24  pt-2   h-16 " src={logo} />
        </div>
        <div className="fixe md:h-full h-0 md:visible md:w-5 w-0 invisible">
        <Dropdown
          title={t("artista.Strumento")}
          value="strumento"
          main="all"
          option={option}
        /></div>
      </div>
      <div class="flex  flex-col items-center md:pt-1.5 pt-10  gap-y-4 md:w-0 md:h-0  visible md:invisible">
        <Dropdown
          title={t("artista.Genere")}
          value="genere"
          main="all"
          option={option} />
        <Dropdown
          title={t("artista.Strumento")}
          value="strumento"
          main="all"
          option={option} />
      </div>
    </div>
  );
}
export default withTranslation()(Navbar);